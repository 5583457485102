<template>
  <router-view v-slot="{ Component }">
    <component
      :is="Component"
      @start="start"
      @move="move"
      @end="end"
    ></component>
  </router-view>
</template>

<script>
import { componentState } from './index.js'
export default {
  name: 'my-page-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
