import { computed, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { onBeforeRouteUpdate } from 'vue-router'
import { CommonFunction } from '@/common/CommonFunction'

export const componentState = () => {
  const { getters } = useStore()
  const state = reactive({
    tabIndex: computed(() => getters['myPage/getTabIndex']),
    chatReadYn: computed(() => getters['myPage/getReadYn']),
    pointer: computed(() => (window.PointerEvent ? true : false)),
    activeTab: 3,
    touch: {}
  })

  onBeforeRouteUpdate((to, from) => {
    // my-page-market-index 구매, 판매 탭 지정해주는 beforeUpdate
    const marketBuyerRouteName = [
      'my-page-market-buyer-view-index',
      'my-page-market-buyer-view-detail-index'
    ]
    const marketSellerRouteName = [
      'my-page-market-buyer-view-index',
      'my-page-market-buyer-view-detail-index',
      'my-page-market-view-index',
      'my-page-market-view-detail-index',
      'my-page-market-edit-index'
    ]
    if (to.name === 'my-page-market-index') {
      let query = { ...to.query }
      if (marketBuyerRouteName.includes(from.name)) {
        query.index = 0
        to.query = query
      } else if (marketSellerRouteName.includes(from.name)) {
        query.index = 1
        to.query = query
      }
    }

    // my-page-adjustment-index 캐시, 포인트 탭 지정해주는 beforeUpdate
    if (to.name === 'my-page-adjustment-index') {
      let query = { ...to.query }
      if (from.params && from.params.name === 'cash') {
        query.index = 0
        to.query = query
      } else if (from.params && from.params.name === 'point') {
        query.index = 1
        to.query = query
      }
    }
  })

  const { switchTab, start, move, end, setTouchPos } = CommonFunction(state)
  return { ...toRefs(state), switchTab, start, move, end, setTouchPos }
}
